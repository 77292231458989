import { type UrlObject } from 'url';
import clsx from 'clsx';
import { type AnchorHTMLAttributes, type ElementType, type ReactNode } from 'react';
import { Link, type RoutesWithoutSlug } from '@/i18n/routing';
type ButtonProps<T extends 'a' | undefined> = AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: ReactNode;
  href: T extends 'a' ? string : RoutesWithoutSlug | (UrlObject & {
    pathname: RoutesWithoutSlug;
  });
  withArrow?: boolean;
  withArrowBack?: boolean;
  withBorder?: boolean;
  isPagination?: boolean;
  tag?: T;
};
export const LinkButton = <T extends 'a' | undefined,>({
  children,
  href,
  withArrow,
  withArrowBack,
  withBorder,
  isPagination,
  tag,
  ...props
}: ButtonProps<T>) => {
  const Tag = (tag || Link) as ElementType;
  return <Tag className={clsx('group relative flex w-fit items-center justify-center gap-2 fill-gray-950 px-1 py-1 font-bold hover:underline', {
    ['border-2 border-gray-950 bg-white !px-7 !py-4 transition-colors duration-300 ease-out hover:bg-gray-950 hover:fill-white hover:text-white hover:no-underline']: withBorder,
    ['pl-12']: withArrowBack,
    ['!h-[46px] !w-[46px] !px-1 !py-1']: isPagination
  })} href={href} {...props} data-sentry-element="Tag" data-sentry-component="LinkButton" data-sentry-source-file="LinkButton.tsx">
			{withArrowBack && <svg className="absolute left-0 transition-all duration-100 ease-out group-hover:-left-2" width="40" height="6" viewBox="0 0 40 6" fill="#03071" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 3L5 5.88675L5 0.113246L0 3ZM40 2.5L4.5 2.5L4.5 3.5L40 3.5L40 2.5Z" fill="#03071" />
				</svg>}

			{children}

			{withArrow && <div className="relative flex w-10 items-center">
					<svg className="absolute right-0 transition-all duration-100 ease-out group-hover:-right-2" width="40" height="6" viewBox="0 0 40 6" fill="#03071" xmlns="http://www.w3.org/2000/svg">
						<path d="M40 3L35 0.113246L35 5.88675L40 3ZM4.37114e-08 3.5L35.5 3.5L35.5 2.5L-4.37114e-08 2.5L4.37114e-08 3.5Z" fill="#03071" />
					</svg>
				</div>}
		</Tag>;
};
LinkButton.displayName = 'LinkButton';