'use client';

import { useTranslations } from 'next-intl';
import { LinkButton } from 'atoms/LinkButton';
import { CONTACT, SUPPORT_EMAIL } from 'constants/contact';
export default function Error({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const t = useTranslations();
  return <html data-sentry-component="Error" data-sentry-source-file="error.tsx">
			<body>
				<section className="relative flex h-screen w-full flex-col items-center justify-center gap-6">
					<div className="relative flex w-full flex-col justify-center px-4">
						<p className="w-full text-center text-[347px] font-extrabold leading-[110%] text-gray-200 max-sm:text-[120px]">
							{t('error-500')}
						</p>

						<p className="absolute mt-28 w-full text-center text-[50px] font-extrabold uppercase tracking-[10px] text-gray-950 max-sm:mt-10 max-sm:text-[18px] max-sm:tracking-[5px]">
							{t('something-went-wrong')}
						</p>
					</div>

					<span className="px-4 text-center">{t('error-500-message')}</span>

					<LinkButton href={`mailto:${CONTACT.EMAIL}?subject=Problemy ze stroną&body=Chciałem/am zgłosić problem związany z działaniem strony.%0D%0A%0D%0ANazwa błędu: '${error.message}'.&cc=${SUPPORT_EMAIL}
					`} withBorder data-sentry-element="LinkButton" data-sentry-source-file="error.tsx">
						{t('contact-with-us')}
					</LinkButton>
				</section>
			</body>
		</html>;
}